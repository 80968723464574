<template>

<div>

  <note :corresSection="1"></note>

  <notes :corresSection="1"></notes>

</div>

</template>

<script>
import Correspondence from '@/components/Correspondence';
import CorrespondenceEntry from '@/components/CorrespondenceEntry';

export default {
  name: 'CorrespondenceList',
  components: {
    Correspondence,
    CorrespondenceEntry,
  },
};
</script>

<style scoped>

</style>
