<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="corresSection === 1" :title-value="'Correspondence'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-mail-bulk</v-icon>
        <v-toolbar-title class="pl-2">Correspondence</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <!--Clear Filter Button -->
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!-- Date -->
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_date"
                        label="Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Correspondence Type -->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.correspondenceTypes"
                    item-text="value"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="
        correspondenceStore.correspondence.data &&
          correspondenceStore.correspondence.data.length > 0
      "
      :showTotal="true"
      :currentPage="correspondenceStore.correspondence.current_page"
      :lastPage="correspondenceStore.correspondence.last_page"
      :total="correspondenceStore.correspondence.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="
          correspondenceStore.correspondence.data &&
            correspondenceStore.correspondence.data.length > 0
        "
        :list-data="correspondenceStore.correspondence.data"
        :title="'Correspondence'"
        :section="corresSection === 3 || corresSection === 4 ? 5.6 : 4.11"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "correspondenceStore/getFilterField",
  mutationType: "correspondenceStore/updateFilterField"
});

export default {
  name: "Correspondence",
  components: {
    TitleBar,
    Pagination,
    ListBasic
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      correspondenceStore: state => state.correspondenceStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields(["page", "section", "fk_providerID", "fk_clientID", "fk_teacherID", "fk_waitingListID", "f_type", "d_date", "date1"])
  },
  props: {
    /* All List = 1, Provider Sublist = 2, Client Sublist = 3, Teacher Sublist = 4, Waiting List Sublist = 5 */
    corresSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      filterMenu: false,
      menu: false
    };
  },
  methods: {
    async initialize() {
      if (this.corresSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      /* ---------- Provider Section ---------- */
      if (this.corresSection === 2) {
        /* Grab provider detail */
        const provider = await this.$store.getters["providersStore/getProvider"];
        /* Hard code provider ID in filter to restrict by provider */
        this.fk_providerID = provider.id;
        this.fk_clientID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      } else if (this.corresSection === 3) {
      /* ---------- Client Section ---------- */
        /* Grab client detail */
        const client = await this.$store.getters["clientsStore/getClient"];
        /* Had code client ID in filter to restrict by client */
        this.fk_providerID = "";
        this.fk_clientID = client.id;
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      } else if (this.corresSection === 4) {
      /* ---------- Teacher Section ---------- */
        /* Grab teacher detail */
        const teacher = await this.$store.getters["teachersStore/getTeacher"];
        /* Had code teacher ID in filter to restrict by teacher */
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_teacherID = teacher.id;
        this.fk_waitingListID = "";
      } else if (this.corresSection === 5) {
      /* ---------- Waiting List Section ---------- */
        /* Grab waiting List detail */
        const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
        /* Had code waiting list ID in filter to restrict by waiting list */
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = waitingListItem.id;
      } else {
        /* Clear hard coded filters */
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      }

      /* Pull correspondence list */
      const filters = this.$store.getters["correspondenceStore/getFilters"];
      await this.$store.dispatch("correspondenceStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_date = "";
      this.date1 = "";
      this.f_type = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      if (this.corresSection !== this.section) {
        this.clearFilterColumns();
      }
      /* Save section in store */
      this.section = this.corresSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.f_type || this.d_date) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.$store.dispatch("correspondenceStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
